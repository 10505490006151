import React, { Dispatch, SetStateAction, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Box, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

interface AvatarProps {
  firstName: string;
  lastName: string;
  setImage: Dispatch<SetStateAction<FileList | null>>;
  image?: { attributes: { url: string } };
}

function stringToColor(string: string) {
  if(string === " "){
    return '#bdbdbd'
  }

  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name: string) {
  const initials = name
    .split(" ")
    .map((word) => word[0])
    .join("");
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}

export default function AvatarProfile({ firstName, lastName, setImage, image }: AvatarProps) {
  const [avatar, setAvatar] = useState<string | null>(null);

  
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImage(event.target.files)
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string); 
      };
      reader.readAsDataURL(file);
    }
  };


  const handleImageDelete = () => {
    setAvatar(null); 
  };

  const fullName = `${firstName?.toLocaleUpperCase()} ${lastName?.toLocaleUpperCase()}`;
  
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap:'5px' }}>
    
      <Avatar
        {...(!avatar && firstName && lastName ? stringAvatar(fullName) : {})}
        src={avatar || undefined}
        sx={{
          height: "100px",
          width: "100px",
          fontSize: "36px",
          bgcolor: !avatar?stringToColor(firstName + " " + lastName):'inherit',
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap:'5px' }}>
        {avatar ? (
          <>
          
            <IconButton component="label" sx={{padding:0}} >
              <EditIcon sx={{ color: "#bdbdbd", cursor: "pointer" }} />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </IconButton>

      
            <IconButton onClick={handleImageDelete} sx={{padding:0}}>
              <DeleteIcon sx={{ color: "#bdbdbd", cursor: "pointer" }} />
            </IconButton>
          </>
        ) : (
          <>
            
            <IconButton component="label" sx={{padding:0}} >
              <AddCircleIcon sx={{ cursor: "pointer", color: "#bdbdbd", height: "36px", width: "36px" }} />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </IconButton>
          </>
        )}
      </Box>
      
    </Box>
  );
}
