import * as React from "react";
import Popover from "@mui/material/Popover";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";

import { Box, IconButton } from "@mui/material";
import { UserDataContext } from "../Context/AllContext";
import LogConfirmation from "./LogConfirmation";

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleLogout = () => {
  //   localStorage.removeItem("access_token");
  //   window.location.href = process.env.REACT_APP_SISURL as string;
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { userData } = React.useContext(UserDataContext);

  return (
    <div>
      <IconButton
        onClick={handleClick}
        sx={{ fontSize: "32px", cursor: "pointer", color: "black", p: "0" }}
      >
        <AccountCircleIcon sx={{ height:'28px', width:'28px'  }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FEF9F3",
            height: "110px",
            width: "250px",
            fontSize: "14px",
            display: "flex",
            boxShadow: "8",
            borderRadius: 1,
            padding: "10px 10px 12px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box
              sx={{
                paddingLeft: "10px",
                paddingRight: "10px",
                fontSize: "14px",
              }}
            >
              <span
                style={{
                  borderBottom: "1px solid #888",
                  paddingBottom: "5px",
                  textAlign: "center",
                }}
              >
                Welcome!, 
                <span style={{ fontWeight: "bold" }}> {userData?.name}</span>
              </span>
            </Box>

            <span
              style={{
                display: "flex",
                gap: "10px",
                paddingLeft: "5px",
                paddingRight: "5px",
                cursor: "not-allowed",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <PersonIcon /> Account
            </span>

              <LogConfirmation />
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
