import React, { createContext, useEffect, useState } from "react";
import Menu from "../Components/Menu";
import Header from "../Components/Header";
// import Alphabet from "../Components/Alphabet";
import axios from "axios";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import Slider from "../Components/Slider";
export const DataContext = createContext<any>(null);
export const LoadingContext = createContext<any>(null);
export const StartingIndexContext = createContext<any>(null);

export interface RowData {
  id: number;
  ContactName: string;
  Expertise: number | null;
  PhoneNum: number | null;
  email: string;
  designation: string | null;
  department: string | null;
  organization: string | null;
  sector: string | null;
  weblink: string | null;
  referrer: string | null;
}

export const headers = { Authorization: `Bearer ${process.env.REACT_APP_STRAPITOKEN}` };

const Layout = () => {

  const [data, setData] = useState<RowData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [startingIndex, setStartingIndex] = useState<number>();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);

  const getStartingPage = () => {
    if (startingIndex) {
      setPage(~~(startingIndex / pageSize));
    }
  };

  useEffect(() => {
    if (startingIndex) {
      getStartingPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingIndex, pageSize]);

  const getEntries = () => {
    axios
      .get(
        `${process.env.REACT_APP_STRAPIURL}/api/entries?sort[0]=sorting_value:asc&populate=Profile_Image&pagination[pageSize]=${pageSize}&pagination[page]=${
          page + 1
        }`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  React.useEffect(() => {
    getEntries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  return (
    <>
        <DataContext.Provider value={{ data, setData, getEntries }}>
          <LoadingContext.Provider value={{ setLoading }}>
            <StartingIndexContext.Provider value={{ setStartingIndex }}>
              <div className="main">
                <Menu />
                <div className="top">
                  <Header />
                  {/* <Alphabet /> */}
                  <Slider page={page} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize}/>
                  
                </div>
              </div>
            </StartingIndexContext.Provider>
          </LoadingContext.Provider>
        </DataContext.Provider>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Layout;
