import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { DataContext } from "../Layout/Layout";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { Typography } from "@mui/material";
import Entry from "./Entry";
import ProfileImage from "./ProfileText";

export default function Table(props: {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  pageSize: number;
  page: number;
}) {
  const { data } = React.useContext(DataContext);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editInfo, setEditInfo] = React.useState<GridRowParams<any>>();

  const columns: GridColDef[] = [
    {
      field: "sn",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            SN
          </Typography>
        );
      },
      flex: 0.5,
      sortable: false,
      renderCell: (row) => {
        return (
          props.page * props.pageSize +
          row.api.getAllRowIds().indexOf(row.row.id) +
          1
        );
      },
    },
    {
      field: "profile",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Profile
          </Typography>
        );
      },
      minWidth: 60,
      flex: 0.5,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return (
          <ProfileImage
            firstName={row.row.attributes.full_name}
            lastName={row.row.attributes.last_name}
            image={row?.row?.attributes?.Profile_Image?.data}
          />
        );
      },
    },
    {
      field: "ContactName",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Name
          </Typography>
        );
      },
      minWidth: 140,
      flex: 2,
      sortable: false,
      renderCell: (row) => {
        return (
          row.row.attributes.full_name + " " + row.row.attributes.last_name
        );
      },
    },
    {
      field: "PhoneNum",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Phone
          </Typography>
        );
      },
      sortable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (row) => {
        return row.row.attributes.Phone;
      },
    },
    {
      field: "email",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Email
          </Typography>
        );
      },
      sortable: false,
      minWidth: 150,
      flex: 2,
      renderCell: (row) => {
        return row.row.attributes.Email;
      },
    },
    {
      field: "SubjectArea",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Subject Area
          </Typography>
        );
      },
      minWidth: 97,
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (row) => {
        return row.row.attributes.subject_area;
      },
    },
    {
      field: "Expertise",
      renderHeader: () => {
        return (
          <Typography fontWeight="700" fontSize={12}>
            Expertise
          </Typography>
        );
      },
      type: "number",
      minWidth: 80,
      headerAlign: "left",
      align: "left",
      sortable: false,
      flex: 1,
      renderCell: (row) => {
        return Array.isArray(row.row.attributes.expertise)
          ? row?.row?.attributes?.expertise?.map(
              (value: string) => `${value}, `
            )
          : "-";
      },
    },
  ];

  return (
    <>
      <Box sx={{ height: "calc(100dvh - 145px)", flex: 1, overflow: "hidden" }}>
        <DataGrid
          hideFooterPagination={true}
          rows={data}
          columns={columns}
          apiRef={props.apiRef}
          scrollbarSize={0}
          disableRowSelectionOnClick
          disableColumnMenu
          onRowClick={(params) => {
            setEditOpen(true);
            setEditInfo(params);
          }}
          sx={{
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none", // Removes the focus outline
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-scrollbar--vertical": {
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
            "& .MuiDataGrid-scrollbar--horizontal": {
              "&::-webkit-scrollbar": {
                height: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "1px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
              },
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
            "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
              borderLeft: "none", // Remove the middle border between the pagination text and page selector
            },
          }}
        />
      </Box>

      <Entry params={editInfo} open={editOpen} setOpen={setEditOpen} />
    </>
  );
}
