import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
// import Dropdown from './Sectordrop';
// import DeleteEntry from "./DeleteEntry";
// import Entry from "./Entry";
import { DataContext, headers } from "../Layout/Layout";
import axios from "axios";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import Entry from "./Entry";

export default function Table2(props: {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { data } = React.useContext(DataContext);

  const [editOpen, setEditOpen] = React.useState(false);

  const [editInfo, setEditInfo] = React.useState<GridRowParams<any>>();

  const [rowCount, setRowCount] = React.useState<number>();

  const getRowCount = () => {
    axios
      .get(
        `${process.env.REACT_APP_STRAPIURL}/api/entries?pagination[pageSize]=0&pagination[page]=0`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setRowCount(res.data.meta.pagination.total);
      })
      .catch(() => {
        toast.error("Failed to fetch data",{
          position:'bottom-left',
        } );
      });
  };

  React.useEffect(() => {
    getRowCount();
  }, []);

  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID',  width:90
    // },
    {
      field: "designation",
      // headerName: "Designation ",
      renderHeader:() =>{
        return(
          <Typography fontWeight= "700" fontSize={12} >
            Designation
          </Typography>
        )
      },
      width: 150,
      sortable: false,
      headerAlign: "left",
      align: "left",
      // flex: 1,
      renderCell: (row) => {
        return row.row.attributes.Designation;
      },
    },
    {
      field: "department",
      // headerName: "Department",
      renderHeader:() =>{
        return(
          <Typography fontWeight= "700" fontSize={12} >
            Department
          </Typography>
        )
      },
      width: 150,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return row.row.attributes.Department;
      },
    },
    {
      field: "organization",
      // headerName: "Organization",
      renderHeader:() =>{
        return(
          <Typography fontWeight= '700' fontSize={12} >
            Organization
          </Typography>
        )
      },
      type: "number",
      sortable: false,
      width: 110,
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return row.row.attributes.Organization;
      },
    },
    {
      field: "sector",
      // headerName: "Sector",
      renderHeader:() =>{
        return(
          <Typography fontWeight= '700' fontSize={12} >
            Sector
          </Typography>
        )
      },
      sortable: false,
      width: 160,
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return row.row.attributes.Sector;
      },
    },
    {
      field: "weblink",
      // headerName: "URL",
      renderHeader:() =>{
        return(
          <Typography fontWeight= '700' fontSize={12}>
            URL
          </Typography>
        )
      },
      sortable: false,
      width: 300,
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return (
          <a
            href={row.row.attributes.Weblink}
            target="blank"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            {row.row.attributes.Weblink}
          </a>
        );
      },
    },
    {
      field: "referrer",
      // headerName: "Referrer",
      renderHeader:() =>{
        return(
          <Typography fontWeight= '700' fontSize={12} >
            Referrer
          </Typography>
        )
      },
      sortable: false,
      width: 120,
      headerAlign: "left",
      align: "left",
      renderCell: (row) => {
        return row.row.attributes.Referrer;
      },
    },
    // {
    //   field: "icon",
    //   headerName: "",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 100,
    //   renderCell: (params) => (
    //     <Box sx={{ fontSize: "small" }} display="flex">
    //       <Entry params={params} />
    //       <DeleteEntry params={params} />
    //     </Box>
    //   ),
    // },
  ];

  return (
    <>
    <Box sx={{ height: "calc(100dvh - 145px)", flex: 1, overflow: "hidden" }}>
      <DataGrid
        rows={data}
        paginationMode="server"
        rowCount={rowCount}
        apiRef={props.apiRef}
        scrollbarSize={0}
        columns={columns}
        disableRowSelectionOnClick
        getRowId={(row) => row?.id}
        disableColumnMenu
        onRowClick={(params) => {
          setEditOpen(true)
          setEditInfo(params);
        }}
        sx={{
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none', 
          },
          "& .MuiDataGrid-scrollbar--vertical": {
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
          "& .MuiDataGrid-scrollbar--horizontal": {
            "&::-webkit-scrollbar": {
              height: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "1px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
          "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
              borderLeft: "none", // Remove the middle border between the pagination text and page selector
            },
        }}
        onPaginationModelChange={(params) => {
          props.setPage(params.page);
          props.setPageSize(params.pageSize);
        }}
        pageSizeOptions={[25, 50, 75, 100]}
        paginationModel={{
          page: props.page,
          pageSize: props.pageSize,
        }}
      />
    </Box>

    <Entry params={editInfo} open={editOpen} setOpen={setEditOpen} />
    </>
    
  );
}
