import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../Layout/Layout";
import CallbackHandler from "../Components/CallbackHandler";
import { useContext, useEffect } from "react";
import axios from "axios";
import LoginGuard from "../services/LoginGuard";
import { UserDataContext } from "../Context/AllContext";
// import Slider from "../Components/Slider";

const Main = () => {
  const accessToken = localStorage.getItem("access_token");

  const {setUserData} = useContext(UserDataContext);

  useEffect(() => {
    if (!accessToken || accessToken === null) {
      return;
    } else {
      axios
        .get(`${process.env.REACT_APP_SISAPI}/api/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(res=>setUserData(res.data))
        .catch(
          () => (window.location.href = process.env.REACT_APP_SISURL as string)
        );
    }
  }, [accessToken, setUserData]);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <LoginGuard>
                {/* <Slider /> */}
                <Layout />
              </LoginGuard>
            }
          />
          <Route path="/callback" element={<CallbackHandler />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Main;
