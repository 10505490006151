import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/material";

export default function BackgroundLetterAvatars(props: {
  firstName: string;
  lastName: string;
  image?: { attributes: { url: string } };
}) {
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar() {
    return {
      sx: {
        bgcolor: stringToColor(props.firstName + " " + props.lastName),
      },
      children: `${props.firstName[0]?.toUpperCase()}${props.lastName[0]?.toUpperCase()}`,
    };
  }

  return (
    <Box display="flex" height="100%" width="100%" alignItems="center">
      {props.image ? (
        <Avatar
          alt="User Image"
          src={`${process.env.REACT_APP_STRAPIURL}${props.image.attributes.url}`}
          sx={{ height: "32px", width: "32px" }}
        />
      ) : (
        <Avatar
          {...stringAvatar()}
          sx={{
            height: "32px",
            width: "32px",
            fontSize: "12px",
            bgcolor: stringToColor(props.firstName + " " + props.lastName),
          }}
        />
      )}
    </Box>
  );
}
