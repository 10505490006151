import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { headers, StartingIndexContext } from "../Layout/Layout";
import { toast } from "react-toastify";

const Alphabet: React.FC = () => {
  interface ISortingValues {
    attributes: {
      sorting_value: string;
    };
    id: number;
  }

  const { setStartingIndex } = useContext(StartingIndexContext);

  const [sortingValues, setSortingValues] = useState<ISortingValues[]>();

  const getSortingValues = () => {
    axios
      .get(
        `${process.env.REACT_APP_STRAPIURL}/api/entries?fields[0]=sorting_value&sort[0]=sorting_value:asc&pagination[pageSize]=99999`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setSortingValues(res.data.data);
      })
      .catch(() => {
        toast.error("Failed to fetch data", {
          position: "bottom-left",
        });
      });
  };

  useEffect(() => {
    getSortingValues();
  }, []);

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const [activeLetter, setActiveLetter] = useState<string | null>(null);

  const handleLetter = (letter: string) => {
    setActiveLetter(letter);
    setStartingIndex(
      sortingValues &&
        sortingValues?.findIndex((item) =>
          item.attributes.sorting_value.startsWith(letter.toLowerCase())
        ) + 1
    );
  };
  return (
    <>
      <div className="alpha">
        {letters.map((letter, index) => (
          <span
            key={index}
            className={`letter${activeLetter === letter ? " active" : ""}`}
            onClick={() => handleLetter(letter)}
          >
            {letter}
          </span>
        ))}
      </div>
    </>
  );
};

export default Alphabet;
