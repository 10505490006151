import React from "react";
import "../App.css";
import GroupIcon from "@mui/icons-material/Group";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForumIcon from "@mui/icons-material/Forum";
import ClassIcon from "@mui/icons-material/Class";
import { Box } from "@mui/material";

interface IconProps {
  className?: string;
}

const Menu: React.FC<IconProps> = ({ className }) => {
  return (
    <>
      <div className="container">
        <div className={`icon-set ${className || ""}`}>
          <GroupIcon className="icon" sx={{ height:'30px', width:'30px' }} />
          <ConfirmationNumberIcon className="icon" sx={{ height:'30px', width:'30px'  }} />
          <CalendarMonthIcon className="icon" sx={{ height:'30px', width:'30px'  }} />
          <ForumIcon className="icon" sx={{ height:'30px', width:'30px'  }} />
          <Box display='flex' justifyContent='center' alignItems='center' bgcolor='rgba(31, 28, 46, 0.08)' width='65px' py='10px' my='-10px'>
          <ClassIcon className="icon" sx={{ height:'30px', width:'30px'  }} />
          </Box>
        </div>
      </div>
    </>
  );
};

export default Menu;
