import React, { createContext, useState } from 'react'

export const UserDataContext = createContext<any>(null);

function AllContext({ children }: { children: React.ReactNode }) {

const [userData, setUserData] = useState();    

  return (
    <>
      <UserDataContext.Provider value={{userData, setUserData}}>
        {children}
      </UserDataContext.Provider>
    </>
  )
}

export default AllContext
