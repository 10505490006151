import { useGridApiRef } from "@mui/x-data-grid";
import Table from "../Components/Table";
import Table2 from "../Components/Table2";
import { useEffect, useRef } from "react";

const AllTables = (props:{
  page: number;
  pageSize: number;
  setPage:React.Dispatch<React.SetStateAction<number>>;
  setPageSize:React.Dispatch<React.SetStateAction<number>>;
}) => {

  const apiRef1 = useGridApiRef();
  const apiRef2 = useGridApiRef();
  // const apiRef3 = useGridApiRef();
  const isScrolling = useRef(false);

  useEffect(() => {
    const gridElement1 =
      apiRef1.current?.rootElementRef?.current?.querySelector<HTMLDivElement>(
        ".MuiDataGrid-virtualScroller"
      );
    const gridElement2 =
      apiRef2.current?.rootElementRef?.current?.querySelector<HTMLDivElement>(
        ".MuiDataGrid-virtualScroller"
      );
    // const gridElement3 =
    //   apiRef3.current?.rootElementRef?.current?.querySelector<HTMLDivElement>(
    //     ".MuiDataGrid-virtualScroller"
    //   );

    if (gridElement1 && gridElement2) {
      const syncScroll1 = () => {
        if (!isScrolling.current) {
          isScrolling.current = true;
          gridElement2.scrollTop = gridElement1.scrollTop;
          // gridElement3.scrollTop = gridElement1.scrollTop;
          isScrolling.current = false;
        }
      };

      const syncScroll2 = () => {
        if (!isScrolling.current) {
          isScrolling.current = true;
          gridElement1.scrollTop = gridElement2.scrollTop;
          // gridElement3.scrollTop = gridElement2.scrollTop;
          isScrolling.current = false;
        }
      };

      // const syncScroll3 = () => {
      //   if (!isScrolling.current) {
      //     isScrolling.current = true;
      //     gridElement1.scrollTop = gridElement3.scrollTop;
      //     gridElement2.scrollTop = gridElement3.scrollTop;
      //     isScrolling.current = false;
      //   }
      // };

      gridElement1.addEventListener("scroll", syncScroll1);
      gridElement2.addEventListener("scroll", syncScroll2);
      // gridElement3.addEventListener("scroll", syncScroll3);

      return () => {
        gridElement1.removeEventListener("scroll", syncScroll1);
        gridElement2.removeEventListener("scroll", syncScroll2);
        // gridElement3.removeEventListener("scroll", syncScroll3);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef1.current, apiRef2.current]);
  
  return (
    <div className="list">
          <Table apiRef={apiRef1} page={props.page} pageSize={props.pageSize} />
          <Table2
            apiRef={apiRef2}
            page={props.page}
            pageSize={props.pageSize}
            setPage={props.setPage}
            setPageSize={props.setPageSize}
          />
          {/* <Table3 apiRef={apiRef3} /> */}
          
        </div>
  )
}

export default AllTables
