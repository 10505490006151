import * as React from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, TextField } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
import { GridRowParams } from "@mui/x-data-grid";
import ComboBox from "./Auto";
import Sectordrop from "./Sectordrop";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { DataContext, headers, LoadingContext } from "../Layout/Layout";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapDialog } from "./Pop";
import DeleteEntry from "./DeleteEntry";
import Expertise from "./Expertise";
import { MobileCode } from "./MobileCode";
import EntryProfile from "./EntryProfile";
// import ProfileImage from "./ProfileText";

export default function Entry(props: {
  params: GridRowParams<any> | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { getEntries } = React.useContext(DataContext);
  const { setLoading } = React.useContext(LoadingContext);

  const [contact, setContact] = React.useState(
    props.params?.row.attributes.full_name
  );
  const [lastName, setLastName] = React.useState(
    props.params?.row.attributes.last_name
  );
  const [subject, setSubject] = React.useState(
    props.params?.row.attributes.subject_area
  );
  const [expertise, setExpertise] = React.useState(
    props.params?.row.attributes.expertise
  );
  const [phone, setPhone] = React.useState(props.params?.row.attributes.Phone);
  const [editedPhone, setEditedPhone] = React.useState(
    props.params?.row.attributes.Phone
  );

  const [email, setEmail] = React.useState(props.params?.row.attributes.Email);
  const [designation, setDesignation] = React.useState(
    props.params?.row.attributes.Designation
  );
  const [department, setDepartment] = React.useState(
    props.params?.row.attributes.Department
  );
  const [organization, setOrganization] = React.useState(
    props.params?.row.attributes.Organization
  );
  const [sector, setSector] = React.useState(
    props.params?.row.attributes.Sector
  );
  const [weblink, setWeblink] = React.useState(
    props.params?.row.attributes.Weblink
  );
  const [referrer, setReferrer] = React.useState(
    props.params?.row.attributes.Referrer
  );
  const [image, setImage] = React.useState(props.params?.row?.attributes?.Profile_Image?.data);

  React.useEffect(() => {
    setContact(props.params?.row.attributes.full_name);
    setLastName(props.params?.row.attributes.last_name);
    setSubject(props.params?.row.attributes.subject_area);
    setExpertise(props.params?.row.attributes.expertise);
    setPhone(props.params?.row.attributes.Phone);
    setEditedPhone(props.params?.row.attributes.Phone);
    setEmail(props.params?.row.attributes.Email);
    setDesignation(props.params?.row.attributes.Designation);
    setDepartment(props.params?.row.attributes.Department);
    setOrganization(props.params?.row.attributes.Organization);
    setSector(props.params?.row.attributes.Sector);
    setWeblink(props.params?.row.attributes.Weblink);
    setReferrer(props.params?.row.attributes.Referrer);
    setImage(props.params?.row.attributes?.Profile_Image?.data);
  }, [props.params]);

  const [errors, setErrors] = React.useState({
    contact: "",
    lastName: "",
    email: "",
    designation: "",
    department: "",
    organization: "",
    weblink: "",
    referrer: "",
    phone: "",
    expertise: "",
  });

  const validate = (name: string, value: string) => {
    let error = "";
    if (
      ((name === "contact" || name === "lastName") && !value.trim()) ||
      (name === "phone" && !value?.split(/ (.+)/)[1]?.trim())
    ) {
      error = "This field is required.";
    } else if (
      [
        "contact",
        "lastName",
        "designation",
        "department",
        "organization",
        "referrer",
      ].includes(name) &&
      /\d/.test(value)
    ) {
      error = "Invalid input, no numbers allowed.";
    } else if (
      value !== "" &&
      name === "email" &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    ) {
      error = "Invalid email format.";
    } else if (
      name === "phone" &&
      !/^\D*(?:\d\D*){10}$/.test(value?.split(/ (.+)/)[1])
    ) {
      error = "Invalid phone number.";
    } else if (
      value !== "" &&
      name === "expertise" &&
      !/^\d{1,2}$/.test(value)
    ) {
      error = "Invalid expertise";
    } else if (
      value !== "" &&
      name === "weblink" &&
      !/^(ftp|http|https):\/\/[^ "]+$/.test(value)
    ) {
      error = "Invalid URL format.";
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  interface IEntryData{
    full_name: string | null;
    last_name: string | null;
    sorting_value: string | null;
    subject_area: string | null;
    expertise: string[] | null;
    Phone: string | null;
    Email: string | null;
    Designation: string | null;
    Department: string | null;
    Organization: string | null;
    Sector: string | null;
    Weblink: string | null;
    Referrer: string | null;
    Profile_Image?: number;
  }

  const createImageEntry = (formData: IEntryData) =>{
    axios
    .put(
      `${process.env.REACT_APP_STRAPIURL}/api/entries/${props.params?.id}`,
      { data: formData },
      { headers: headers }
    )
    .then((res) => {
      getEntries();
      toast.success("Entry Updated successfully", {
        position: "bottom-left",
      });
      props.setOpen(false);
      setLoading(false);
    })
    .catch((err) => {
      toast.error("Update failed", {
        position: "bottom-left",
      });
      setLoading(false);
    });
};
  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    validate("contact", contact);
    validate("phone", phone);
    validate("lastName", lastName);

    if (
      !contact.trim() ||
      !phone.trim() ||
      !lastName.trim() ||
      errors.contact ||
      errors.phone ||
      errors.lastName
    ) {
      setLoading(false);
      return;
    }

    const imageEntry = new FormData();

    if (image && image.length > 0){
      imageEntry.append("files", image[0]);
      imageEntry.append("ref", "entry");
      imageEntry.append("field", "Profile_Image");
    }
  

    const formData: IEntryData = {
      full_name: contact === "" ? null : contact,
      last_name: lastName === "" ? null : lastName,
      sorting_value: contact === "" ? null : contact?.toLowerCase(),
      subject_area: subject?.toLowerCase() === "" ? null : subject,
      expertise: expertise === 0 ? null : expertise,
      Phone: editedPhone === "" ? null : editedPhone,
      Email: email === "" ? null : email,
      Designation: designation === "" ? null : designation,
      Department: department === "" ? null : department,
      Organization: organization === "" ? null : organization,
      Sector: sector?.toLowerCase() === "" ? null : sector,
      Weblink: weblink === "" ? null : weblink,
      Referrer: referrer === "" ? null : referrer,
      Profile_Image: image === null || image.length === 0 ? null : image,
    };

    if(image && image.length > 0){
      axios
        .post(`${process.env.REACT_APP_STRAPIURL}/api/upload`,imageEntry,{
          headers:headers,
        })
        .then((res)=>{
          formData.Profile_Image = res.data[0].id;
          createImageEntry(formData);
        });
    }else{
      createImageEntry(formData);
    }
  }

    

  //   const handleEdit = (
  //     params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  //   ) => {
  //     console.log(props.params);
  //     // axios
  //     //   .put(`${process.env.REACT_APP_STRAPIURL}/api/entries/${params.id}`)
  //     //   .then((res) => {
  //     //     console.log("Editted successfully")
  //     //   });
  //   };

  const handleClose = () => {
    props.setOpen(false);
    setContact(props.params?.row.attributes.full_name);
    setLastName(props.params?.row.attributes.last_name);
    setPhone(props.params?.row.attributes.Phone);
    setEditedPhone(props.params?.row.attributes.Phone);
    setExpertise(props.params?.row.attributes.expertise);
    setEmail(props.params?.row.attributes.email);
    setDesignation(props.params?.row.attributes.Designation);
    setDepartment(props.params?.row.attributes.Department);
    setOrganization(props.params?.row.attributes.Organization);
    setSector(props.params?.row.attributes.Sector);
    setWeblink(props.params?.row.attributes.Weblink);
    setReferrer(props.params?.row.attributes.Referrer);
    setSubject(props.params?.row.attributes.subject_area);
    setImage(props.params?.row.attributes?.Profile_Image?.data);
  };

  const handleKeyDown = (e:React.KeyboardEvent) =>{
    if(e.key === 'Enter'){
      handleSubmit(e as React.FormEvent)
    }
  };

  return (
    <React.Fragment>
      {/* <EditIcon
        onClick={() => handleClickOpen()}
        sx={{ width: "20px", height: "20px", cursor: "pointer" }}
      /> */}

      <BootstrapDialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="alert-dialog-title">
          {"Edit"}
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              color: "gray",
              cursor: "pointer",
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
              }}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              onKeyDown={handleKeyDown}
            >
              <EntryProfile
                firstName={contact}
                lastName={lastName}
                image={image}
                setImage={setImage}
              />
              {/* <ProfileImage firstName={contact} lastName={lastName} image={image}  /> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <TextField
                  id="name"
                  name="contact"
                  label="Contact Name"
                  size="small"
                  variant="outlined"
                  required
                  sx={{ flex: 1 }}
                  value={contact}
                  onChange={(e) => {
                    setContact(e.target.value);
                    validate("contact", e.target.value);
                  }}
                  error={!!errors.contact}
                  helperText={errors.contact}
                />
                <TextField
                  id="name"
                  name="contact"
                  label="Last Name"
                  size="small"
                  required
                  sx={{ flex: 1 }}
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    validate("lastName", e.target.value);
                  }}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                {/* <TextField
                  id="exp"
                  label="Expertise"
                  variant="outlined"
                  value={expertise}
                  size="small"
                  onChange={(e) => {
                    setExpertise(e.target.value);
                    validate("expertise", e.target.value);
                  }}
                  error={!!errors.expertise}
                  helperText={errors.expertise}
                /> */}
                <MobileCode
                  value={phone}
                  required
                  sx={{ flex: 1 }}
                  onChange={setEditedPhone}
                  validate={validate}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
                {/* <TextField
                  id="phone"
                  label="Mobile"
                  size="small"
                  variant="outlined"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    validate("phone", e.target.value);
                  }}
                  error={!!errors.phone}
                  helperText={errors.phone}
                /> */}
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  sx={{ flex: 1 }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validate("email", e.target.value);
                  }}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <ComboBox
                  variant="outlined"
                  spacing="100%"
                  setSubject={setSubject}
                  subject={subject}
                  small
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <Expertise
                  variant="outlined"
                  spacing="100%"
                  setSelectedExpertise={setExpertise}
                  selectedExpertise={expertise}
                  small
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <TextField
                  id="designation"
                  label="Designation"
                  size="small"
                  variant="outlined"
                  sx={{ flex: 1 }}
                  value={designation}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                    validate("designation", e.target.value);
                  }}
                  error={!!errors.designation}
                  helperText={errors.designation}
                />
                <TextField
                  id="department"
                  label="Department"
                  size="small"
                  variant="outlined"
                  sx={{ flex: 1 }}
                  value={department}
                  onChange={(e) => {
                    setDepartment(e.target.value);
                    validate("department", e.target.value);
                  }}
                  error={!!errors.department}
                  helperText={errors.department}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <TextField
                  id="organization"
                  label="Organization"
                  size="small"
                  sx={{ flex: 1 }}
                  variant="outlined"
                  value={organization}
                  onChange={(e) => {
                    setOrganization(e.target.value);
                    validate("organization", e.target.value);
                  }}
                  error={!!errors.organization}
                  helperText={errors.organization}
                />
                <Sectordrop
                  variant="outlined"
                  spacing="49%"
                  setSector={setSector}
                  sector={sector}
                  small
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <TextField
                  id="web"
                  label="URL"
                  variant="outlined"
                  size="small"
                  sx={{ flex: 1 }}
                  value={weblink}
                  onChange={(e) => {
                    setWeblink(e.target.value);
                    validate("weblink", e.target.value);
                  }}
                  error={!!errors.weblink}
                  helperText={errors.weblink}
                />
                <TextField
                  id="ref"
                  label="Referrer"
                  variant="outlined"
                  size="small"
                  sx={{ flex: 1 }}
                  value={referrer}
                  onChange={(e) => {
                    setReferrer(e.target.value);
                    validate("referrer", e.target.value);
                  }}
                  error={!!errors.referrer}
                  helperText={errors.referrer}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "black",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  autoFocus
                  sx={{
                    color: "white",
                    bgcolor: "#1976d2",
                    marginTop: "12px",
                    // mx: "auto",
                  }}
                >
                  Save
                </Button>
                <DeleteEntry
                  id={props.params?.id as number}
                  setEditOpen={props.setOpen}
                />
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </React.Fragment>
  );
}
