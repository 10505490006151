import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CallbackHandler = () => {
  const url = window.location;
  const access_token = new URLSearchParams(url.search).get('access_token') ?? '';
  const navigate = useNavigate();

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwtDecode(access_token);

      localStorage.setItem('access_token', access_token ?? '');
      localStorage.setItem('exp', decodedToken?.exp?.toString() ?? '');
      const navTimeout = setTimeout(() => {
        navigate('/');
      }, 2000);

      return () => {
        clearTimeout(navTimeout);
      };
    }
  }, [access_token, navigate]);

  return <div>Loading...</div>;
};

export default CallbackHandler;